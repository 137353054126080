<template>
  <v-dialog v-model="isOpenDialog" max-width="800">
    <v-card>
      <v-card-title>
        <span class="search--text">การเตือนภัย</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <div class="py-4 px-6 px-sm-16 black--text">
          <div class="mt-2">
            <v-data-table :headers="headers" :items="dataRow" disable-sort fixed-header hide-default-footer items-per-page="99999"
                          mobile-breakpoint="0"></v-data-table>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="py-4 px-6 px-sm-16 black--text">
        <v-spacer/>
        <v-btn @click="isOpenDialog=false">ปิด</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import utils from '../../assets/js/utils'

export default {
  name: 'CompanyNotification',
  props: {
    isShow: Boolean,
    value: Object,
    notificationData: Object
  },
  data () {
    return {
      isOpenDialog: false,
      headers: [
        {
          text: 'วันที่',
          value: 'monitoringDate'
        },
        {
          text: 'จุดตรวจวัด',
          value: 'measCode'
        },
        {
          text: 'พารามิเตอร์',
          value: 'paramName'
        },
        {
          text: 'ค่าที่แจ้งเตือน',
          value: 'paramValue'
        },
        {
          text: 'หน่วย',
          value: 'paramUnit'
        },
        {
          text: 'สถานะการส่ง',
          value: 'status.name'
        }
      ]
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    dataRow () {
      if (!this.notificationData.items) return []
      return this.notificationData.items.map(
        item => ({
          ...item,
          monitoringDate: utils.dateToThai(item.monitoringDate.split(' ')[0]) + ' ' +
            item.monitoringDate.split(' ')[1].substr(0, 5) + ' น.'
        })
      )
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
  max-height: 500px;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
