<!-- to edit map popup goto methods placeMarkers()-->

<template>
  <div class="d-flex fill-height companyPage">
    <div id="detailDom" class="leftPanel white">
      <div class="px-2 py-2 py-sm-0 d-flex listItemContainer"
           @touchend.passive="calculateHeightEnd"
           @touchmove.passive="calculateHeight"
           @touchstart.passive="calculateHeightStart">
        <v-icon class="pr-0 pr-sm-2 d-flex d-sm-none" @click="$router.push({name:'Sensor'})">
          mdi-chevron-left
        </v-icon>
        <company-list-item :id="companyData.id"
                           :address="companyData.address"
                           :isShowChip="true"
                           :logo="companyData.logo"
                           :name="companyData.name"
                           :no="companyData.no"
                           :severityCems="companyData.severityCems"
                           :severityOpms="companyData.severityOpms"
                           :severityStation="companyData.severityStation"
                           class="pt-2 pb-2 pl-1 cli_component"/>
      </div>
      <v-divider v-if="isTouch"></v-divider>
      <div v-show="!isTouch" class="mobileCompanyContentScroller">
        <div class="d-flex label py-2 pl-3">
          <v-icon color="white">mdi-information-outline</v-icon>
          <span class="white--text">สรุป</span>
        </div>
        <div class="pa-3">
          <div class="d-flex align-center">
            <span class="d-none d-sm-block" style="font-size: 18px;">สถิติข้อมูล</span>
            <v-spacer class="d-none d-sm-block"/>
            <span style="min-width: 95px;">ข้อมูล ณ วันที่</span>
            <v-spacer class="d-block d-sm-none"/>
            <div class="mx-2" style="max-width: 180px;">
              <my-date-picker v-model="selectedDate" :max="maxRecordedDate" buttonColor="search white--text"
                              color="search" header-color="search"></my-date-picker>
            </div>
          </div>
          <div class="mt-3 mt-sm-0">
            <v-tabs v-model="tab" color="search">
              <v-tab v-if="countCems > 0">
                CEMS
              </v-tab>
              <v-tab v-if="countOpms > 0">
                WPMS
              </v-tab>
              <v-tab v-if="countMobile > 0">
                MOBILE
              </v-tab>
              <v-tab v-if="countStation > 0">
                STATION
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-if="countCems > 0">
                <sensor-table v-show="!isFetchingSensor" :click-text="true" :sensorData="cemsData"
                              v-on:clickSensorValue="processSensorValueSignal" v-on:clickUnit="processSensorUnit"
                              v-on:measurementCode="processMeasurementCode"/>
                <div v-if="isFetchingSensor" class="d-flex justify-center" style="width: 100%">
                  <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
              <v-tab-item v-if="countOpms > 0">
                <sensor-table v-show="!isFetchingSensor" :click-text="true" :sensorData="opmsData"
                              v-on:clickSensorValue="processSensorValueSignal" v-on:clickUnit="processSensorUnit"
                              v-on:measurementCode="processMeasurementCode"/>
                <div v-if="isFetchingSensor" class="d-flex justify-center" style="width: 100%">
                  <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
              <v-tab-item v-if="countMobile > 0">
                <sensor-table v-show="!isFetchingSensor" :click-text="true" :sensorData="mobileData"
                              v-on:clickSensorValue="processSensorValueSignal" v-on:clickUnit="processSensorUnit"
                              v-on:measurementCode="processMeasurementCode"/>
                <div v-if="isFetchingSensor" class="d-flex justify-center" style="width: 100%">
                  <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
              <v-tab-item v-if="countStation > 0">
                <sensor-table v-show="!isFetchingSensor" :click-text="true" :sensorData="stationData"
                              v-on:clickSensorValue="processSensorValueSignal" v-on:clickUnit="processSensorUnit"
                              v-on:measurementCode="processMeasurementCode"/>
                <div v-if="isFetchingSensor" class="d-flex justify-center" style="width: 100%">
                  <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div class="mt-5 d-flex align-center">
            <span style="font-size: 18px;">
              ค่าแนวโน้มสถานการณ์มลพิษ
              <span class="mr-1" style="font-size: 16px;">{{ guidedObject.code }} : {{ guidedObject.name }}</span>
            </span>
            <v-spacer/>
            <v-btn class="primary" elevation="0" @click="openExportDialog()">
              <v-icon class="mr-2" dense>mdi-send</v-icon>
              ส่งออก
            </v-btn>
          </div>
          <div class="mt-3" style="width: 100%;">
            <company-line-chart :graphData="graphData" :graphUnit="graphUnit" :isLoading="isLoadingGraph"/>
            <div style="max-width: 450px; margin: 0 auto;">
              <sensor-table v-show="Object.keys(graphTable.measurements).length > 0" :sensorData="graphTable"
                            :translateMinusToErr="true"/>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-3">
            <div class="error rounded-lg d-flex flex-column pa-3 elevation-1 mr-0 mr-sm-3 card mb-2">
              <div class="white--text" style="width: 100%">
                จำนวนครั้งในการเตือนภัย
              </div>
              <div class="mt-auto white--text d-flex" style="width: 100%">
                <div class="d-flex align-end pb-2">
                  <u><a class="white--text" @click="notificationDialog=true">ดูเพิ่มเติม</a></u>
                </div>
                <v-spacer/>
                <div style="font-size: 36px;">
                  {{ totalNotify }}
                </div>
              </div>
            </div>
            <div class="search rounded-lg d-flex flex-column pa-3 elevation-1 card mt-3 mt-sm-0 mb-2">
              <div class="white--text" style="width: 100%">
                การส่งข้อมูลสำเร็จในแต่ละวัน
              </div>
              <div class="mt-auto white--text d-flex" style="width: 100%">
                <v-spacer/>
                <div style="font-size: 36px;">
                  {{ successRate }}%
                </div>
              </div>
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.xs" class="mt-3">
            <div class="mb-2" style="font-size: 18px;">รูปภาพอุปกรณ์ตรวจวัด</div>
            <div class="black" style="height: 300px;">
              <my-carousel :author="items.map(item => item.author || '')"
                           :description="items.map(item => item.description || '')"
                           :src="items.map(item => item.src || '')"></my-carousel>
            </div>
          </div>
          <v-divider class="my-5"></v-divider>
          <div>
            <div v-if="Object.keys(contactPerson).length > 0" style="font-size: 18px;">ข้อมูลผู้ติดต่อ</div>
            <v-row class="ma-0">
              <v-col v-for="(obj) in contactPerson" :key="obj.id" class="pa-1" cols="12" md="6">
                <div class="py-2 px-3" style="background-color: rgba(24, 103, 192, 0.13); border-radius: 16px; font-size: 14px;">
                  <v-row class="mb-2 mb-sm-0" no-gutters>
                    <v-col cols="12" sm="4">
                      ชื่อ:
                    </v-col>
                    <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                      {{ obj.name }}
                    </v-col>
                  </v-row>
                  <v-row class="mb-2 mb-sm-0" no-gutters>
                    <v-col cols="12" sm="4">
                      ตำแหน่ง:
                    </v-col>
                    <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                      {{ obj.position }}
                    </v-col>
                  </v-row>
                  <v-row class="mb-2 mb-sm-0" no-gutters>
                    <v-col cols="12" sm="4">
                      โทร:
                    </v-col>
                    <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                      {{ obj.tel }}
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <div class="mt-2" style="font-size: 18px;">ข้อมูลอื่นๆ</div>
            <div class="mt-2 pa-3" style="background-color: rgba(0, 0, 0, 0.13); border-radius: 16px; font-size: 14px;">
              <v-row class="mb-2 mb-sm-0" no-gutters>
                <v-col cols="12" lg="4" sm="6">
                  ที่อยู่:
                </v-col>
                <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                  {{ companyData.address }}
                </v-col>
              </v-row>
              <v-row class="mb-2 mb-sm-0" no-gutters>
                <v-col cols="12" lg="4" sm="6">
                  เบอร์โทร:
                </v-col>
                <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                  {{ contactPerson.tel || '-' }}
                </v-col>
              </v-row>
              <v-row class="mb-2 mb-sm-0" no-gutters>
                <v-col cols="12" lg="4" sm="6">
                  ผู้รับผิดชอบ:
                </v-col>
                <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                  <span v-if="contactPerson.name">{{ contactPerson.name }} ( {{ contactPerson.position }} )</span>
                  <span v-else>-</span>
                </v-col>
              </v-row>
              <v-row class="mb-2 mb-sm-0" no-gutters>
                <v-col cols="12" lg="4" sm="6">
                  เลขทะเบียนโรงงาน:
                </v-col>
                <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                  {{ companyData.no }}
                </v-col>
              </v-row>
              <v-row class="mb-2 mb-sm-0" no-gutters>
                <v-col cols="12" lg="4" sm="6">
                  ประเภทโรงงาน:
                </v-col>
                <v-col class="pl-3 pl-sm-0" cols="12" sm="6">
                  -
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mapDom" class="rightPanel">
      <div class="carouselContainer d-none d-sm-block">
        <div style="height: 30px;">
          รูปภาพอุปกรณ์ตรวจวัด
        </div>
        <div class="black d-flex align-center justify-center" style="height: calc(100% - 30px);">
          <my-carousel v-if="$vuetify.breakpoint.smAndUp" :author="items.map(item => item.author || '')"
                       :description="items.map(item => item.description || '')"
                       :src="items.map(item => item.src || '')"></my-carousel>
        </div>
      </div>
      <div id="cmap" class="map">
      </div>
    </div>
    <v-overlay :value="isFetching" style="z-index: 100;">
      <div class="d-flex justify-center text-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
      <div>
        กำลังโหลดข้อมูล
      </div>
    </v-overlay>
    <export-company v-model="exportData" :companyPlaceHolder="companyData.name" :isShow="exportDialog"
                    :measurementList="measurements" v-on:isShowSignal="updateExportDialog"/>
    <company-notification :isShow="notificationDialog" :notificationData="notificationData"
                          v-on:isShowSignal="updateNotificationDialog"></company-notification>
  </div>
</template>

<script>
import CompanyListItem from '../components/CompanyListItem'
import CompanyLineChart from '../components/charts/CompanyLineChart'
import { mapState } from 'vuex'
import MyCarousel from '../components/myCarousel'
import ExportCompany from '../components/dialog/ExportCompany'
import SensorTable from '../components/sensorTable'
import myDatePicker from '../components/myDatePicker'
import CompanyNotification from '../components/dialog/CompanyNotification'
import utils from '../assets/js/utils'

export default {
  name: 'Company',
  components: {
    CompanyNotification,
    SensorTable,
    ExportCompany,
    MyCarousel,
    CompanyLineChart,
    CompanyListItem,
    myDatePicker
  },
  async mounted () {
    this.isFetching = true
    this.$store.dispatch('ref/getParameterList').then(() => {}) // do not wait this line

    // do fetch data
    const id = this.$route.query.id
    const uuid = this.uuid || ''
    const dispatch = this.$store.dispatch('company/fetchCompanyDetail', { id: id, uuid: uuid })
    await this.createLongdoMap() // need to be finish before getElement

    // do instant task
    this.pageDom = document.getElementsByClassName('companyPage')[0]
    this.detailDom = document.getElementById('detailDom')
    this.mapDom = document.getElementById('mapDom')
    this.computeFirstLayout()

    // do promise task
    const data = await dispatch
    this.placeData(data)
    this.selectedDate = this.maxRecordedDate

    this.isFetching = false
  },
  data () {
    return {
      scrollPosition: 0,
      pageDom: null,
      detailDom: null,
      mapDom: null,
      map: null,
      maxRecordedDate: '',
      contactPerson: {},
      selectedDate: '',
      isLoadingGraph: false,
      companyData: {},
      cemsData: {},
      opmsData: {},
      mobileData: {},
      stationData: {},
      measurements: [],
      graphData: {},
      totalNotify: 0,
      successRate: 0,
      graphUnit: '',
      tab: 0,
      guidedObject: {},
      items: [], // อุปกรณ์ตรวจวัด
      isTouch: false,
      isFetching: false,
      isFetchingSensor: false,
      exportDialog: false,
      notificationDialog: false,
      exportData: {},
      marker: require('../assets/img/marker.png'),
      graphTable: {
        measurements: {},
        parameters: {}
      },
      notificationData: {},
      countCems: 1,
      countOpms: 1,
      countMobile: 1,
      countStation: 1,
      previousE: false
    }
  },
  methods: {
    async createLongdoMap () {
      // create longdo map
      this.map = new window.longdo.Map({
        placeholder: document.getElementById('cmap')
      })
      try {
        // manage cache longdo map layer
        await (window.longdoJsInterface.Util.cacheLongdoMapLayer(window.longdo, 'diw_front_cache', this.map))
      } catch (e) {
        console.warn('At cacheLongdoMapLayer', e)
      }
      try {
        this.map.Layers.setBase(window.longdo.Layers.POI)
        this.map.enableFilter(window.longdo.Filter.Light)
      } catch (e) {
        console.warn('At [setBase or enableFilter]', e)
      }
      if (this.$vuetify.breakpoint.xs) {
        this.map.Ui.Zoombar.visible(false)
      }
      // this.map.Ui.LayerSelector.visible(false)
    },
    placeData (data) {
      this.companyData.address = data.address
      this.contactPerson = data.contact
      this.totalNotify = data.countAlert
      const geom = String(data.geom).replace('POINT', '').replace('(', '').replace(')', '')
      const lonlat = geom.split(' ')
      this.companyData.lon = parseFloat(lonlat[0])
      this.companyData.lat = parseFloat(lonlat[1])
      this.companyData.logo = data.logo
      this.maxRecordedDate = String(data.maxRecordedDate).split(' ')[0] || new Date().toISOString().substr(0, 10)
      if (this.maxRecordedDate === 'null') {
        this.maxRecordedDate = new Date().toISOString().substr(0, 10)
      }
      this.measurements = data.measurements
      this.companyData.name = data.name
      this.companyData.no = data.noNew || data.no
      this.companyData.id = data.id
      this.successRate = data.percentSuccess
      this.companyData.severityCems = data.severityCems
      this.companyData.severityOpms = data.severityOpms
      this.companyData.severityStation = data.severityStation
      this.countCems = data.countCems
      this.countOpms = data.countOpms
      this.countMobile = data.countMobile
      this.countStation = data.countStation
      // carousel region
      this.items = data.image
      this.map.location({ lat: parseFloat(lonlat[1]), lon: parseFloat(lonlat[0]) }, true)
      this.map.zoom(16)
      return true
    },
    doMergeMarker () {
      let allMeasurements = {}
      let allMeasurementList = []
      if (this.cemsData.measurements && Object.keys(this.cemsData.measurements).length > 0) {
        allMeasurements = { ...allMeasurements, ...this.cemsData.measurements }
      }
      if (this.opmsData.measurements && Object.keys(this.opmsData.measurements).length > 0) {
        allMeasurements = { ...allMeasurements, ...this.opmsData.measurements }
      }
      if (this.mobileData.measurements && Object.keys(this.mobileData.measurements).length > 0) {
        allMeasurements = { ...allMeasurements, ...this.mobileData.measurements }
      }
      if (this.stationData.measurements && Object.keys(this.stationData.measurements).length > 0) {
        allMeasurements = { ...allMeasurements, ...this.stationData.measurements }
      }
      const keys = Object.keys(allMeasurements)
      keys.forEach(
        name => {
          allMeasurementList.push(allMeasurements[name])
        }
      )
      // important !!, deep copy before edit js Object
      allMeasurementList = JSON.parse(JSON.stringify(allMeasurementList))
      allMeasurementList = allMeasurementList.map(
        item => {
          const geom = String(item.geom).replace('POINT', '').replace('(', '').replace(')', '')
          const lonlat = geom.split(' ')
          item.location = {}
          item.location.lon = parseFloat(lonlat[0])
          item.location.lat = parseFloat(lonlat[1])
          item.parameters = Object.keys(item.parameters).map(
            key => {
              const here = item.parameters[key]
              const param = this.parameterList.find(p => String(p.id) === String(key))
              if (!param) {
                console.log(key, item.typeName)
                return { ...here, name: '', unit: '' }
              }
              const NameUnit = param.name.split(' ')
              return {
                ...here,
                name: NameUnit[0],
                unit: NameUnit[1]
              }
            }
          )
          return item
        }
      )
      this.measurements = allMeasurementList
    },
    placeMarkers () {
      const pollutionDiv = (measurement) => {
        // to locate css class in this template please look at app.vue
        let template =
          '<div class="mapPopupBlock">' +
            '<div class="mapPopupValue">' +
              '<div>' +
                '<span class="mr-1">headerPlaceHere</span>' +
                '<span style="font-size: 11px;">( unitPlaceHere )</span>' +
              '</div>' +
              '<div class="mapPopupNumber">valuePlaceHere</div>' +
            '</div>' +
          '</div>'
        template = template.replace('headerPlaceHere', measurement.name)
        template = template.replace('unitPlaceHere', measurement.unit)
        template = template.replace('valuePlaceHere', measurement.value)
        return template
      }
      const updateDetail = (sensor) => (element) => {
        // to locate css class in this template please look at app.vue
        let template =
        '<div style="font-size: 14px;">' +
          '<div class="d-flex" style="width: 100%">' +
            '<div style="width: 100%;" class="mapPopupCompanyDetail">' +
              '<div>Measurement name : placeSensorTitleHere</div>' +
              '<div style="font-size: 12px;">Sensor type : placeDetailHere</div>' +
              '<div style="font-size: 12px;">Recorded date : placeTimeHere</div>' +
            '</div>' +
          '</div>' +
          '<div style="width: 100%" class="d-flex mt-3 flex-wrap">' +
            'blocksPlaceHere' +
          '</div>' +
        '</div>'
        template = template.replace('placeSensorTitleHere', sensor.measName)
        template = template.replace('placeDetailHere', sensor.typeName)
        template = template.replace('placeTimeHere', utils.dateToThai(this.selectedDate))
        let dataBlocks = ''
        sensor.parameters.forEach(
          item => {
            if (item.value !== null) {
              const myBlock = pollutionDiv(item)
              dataBlocks += myBlock
            }
          }
        )
        template = template.replace('blocksPlaceHere', dataBlocks)
        element.innerHTML = template
      }
      this.map.Overlays.clear()
      for (const sensor of this.measurements) {
        if (sensor.parameters.length > 0) {
          const marker = new window.longdo.Marker(
            sensor.location,
            {
              loadDetail: updateDetail(sensor),
              detail: 'html element will be place here using callback function',
              icon: { url: this.marker }
            }
          )
          this.map.Overlays.add(marker)
        }
      }
    },
    calculateHeightStart (e) {
      this.isTouch = true
    },
    calculateHeight (e) {
      const doTouch = () => {
        const clientY = e.changedTouches[0].clientY
        const screenHeight = this.pageDom.offsetHeight
        if (this.$vuetify.breakpoint.xs && clientY > screenHeight * 0.1 && clientY < screenHeight * 0.8) {
          const detailHeight = screenHeight - clientY
          const mapHeight = screenHeight - detailHeight + 8
          this.detailDom.style.top = clientY + 'px'
          this.detailDom.style.height = detailHeight + 'px'
          this.mapDom.style.height = mapHeight + 'px'
        }
      }
      if (requestAnimationFrame) {
        requestAnimationFrame(doTouch)
      } else {
        doTouch()
      }
    },
    calculateHeightEnd (e) {
      this.isTouch = false
      window.dispatchEvent(new Event('resize'))
      const clientY = e.changedTouches[0].clientY
      const screenHeight = this.pageDom.offsetHeight
      const detailHeight = screenHeight - clientY
      this.$store.commit('UPDATE_DETAIL_TOP', clientY)
      this.$store.commit('UPDATE_DETAIL_HEIGHT', detailHeight)
    },
    computeFirstLayout () {
      const screenHeight = this.pageDom.offsetHeight
      if (this.$vuetify.breakpoint.xs) {
        const detailTopValue = parseInt(screenHeight * 0.7)
        const detailHeight = parseInt(screenHeight * 0.3)
        const mapHeight = parseInt(screenHeight * 0.7) + 8
        this.detailDom.style.top = detailTopValue + 'px'
        this.detailDom.style.height = detailHeight + 'px'
        this.mapDom.style.height = mapHeight + 'px'
      } else {
        this.detailDom.style = null
        this.mapDom.style = null
      }
    },
    updateExportDialog (e) {
      // e is true-false
      if (e !== this.exportDialog) {
        this.exportDialog = e
      }
    },
    updateNotificationDialog (e) {
      if (e !== this.notificationDialog) {
        this.notificationDialog = e
      }
    },
    async fetchSensorAtSelectedDate () {
      this.isFetchingSensor = true
      const id = this.$route.query.id
      const uuid = this.uuid || ''
      const promiseArray = []
      if (this.countCems > 0) {
        const sensorCEMSPromise = this.$store.dispatch('sensor/getSensorData', {
          id: id,
          type: 1,
          date: this.selectedDate,
          uuid: uuid
        }).then(
          (data) => {
            this.cemsData = data
          }
        )
        promiseArray.push(sensorCEMSPromise)
      }
      if (this.countOpms > 0) {
        const sensorOPMSPromise = this.$store.dispatch('sensor/getSensorData', {
          id: id,
          type: 2,
          date: this.selectedDate,
          uuid: uuid
        }).then(
          (data) => {
            this.opmsData = data
          }
        )
        promiseArray.push(sensorOPMSPromise)
      }
      if (this.countMobile > 0) {
        const sensorMobilePromise = this.$store.dispatch('sensor/getSensorData', {
          id: id,
          type: 3,
          date: this.selectedDate,
          uuid: uuid
        }).then(
          (data) => {
            this.mobileData = data
          }
        )
        promiseArray.push(sensorMobilePromise)
      }
      if (this.countStation > 0) {
        const sensorStationPromise = this.$store.dispatch('sensor/getSensorData', {
          id: id,
          type: 4,
          date: this.selectedDate,
          uuid: uuid
        }).then(
          (data) => {
            this.stationData = data
          }
        )
        promiseArray.push(sensorStationPromise)
      }
      await Promise.all(promiseArray)
      this.isFetchingSensor = false
    },
    async processSensorValueSignal (e) {
      this.previousE = e
      this.isLoadingGraph = true
      this.graphTable.parameters = {}
      this.graphTable.measurements = {}
      const uuid = this.uuid || ''
      // do task
      const parameterId = parseInt(e.parameterId)
      this.graphTable.parameters[parameterId] = this.parameterList.find(item => item.id === parameterId)
      // do long task
      const graphDataPromise = this.$store.dispatch('sensor/getSensorGraph', {
        ...e,
        date: this.selectedDate,
        uuid: uuid
      })
      const notificationPromise = this.$store.dispatch('company/getNotificationList', {
        uuid: uuid,
        factory: this.$route.query.id,
        measurement: e.measurementId,
        parameter: e.parameterId,
        frequency: 'daily',
        date: this.selectedDate
      })
      // process long task
      const graphData = await graphDataPromise
      this.graphData = graphData
      graphData.values.forEach(
        (value, index) => {
          const data = {
            code: this.guidedObject.code,
            measName: this.guidedObject.measName,
            parameters: {},
            recordedDate: this.selectedDate + ' ' + value.time
          }
          data.parameters[parameterId] = { errorCode: value.errorCode, isError: !!value.isError, errMsg: value.errMsg || '', severity: 0, value: value.value }
          const paddedIndex = String(index).padStart(4, '0')
          this.graphTable.measurements[paddedIndex] = data
        }
      )
      this.notificationData = await notificationPromise
      this.totalNotify = graphData.countAlert
      this.successRate = graphData.percentSuccess
      this.graphTable = { ...this.graphTable } // tell vue to update template
      this.isLoadingGraph = false
    },
    processSensorUnit (unit) {
      this.graphUnit = unit
    },
    processMeasurementCode (code) {
      this.guidedObject = code
    },
    openExportDialog () {
      this.exportData = {
        id: this.companyData.id,
        name: this.companyData.name
      }
      this.exportDialog = true
    }
  },
  computed: {
    ...mapState(['mobileDetailHeight', 'mobileDetailTop']),
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['parameterList'])
  },
  watch: {
    async selectedDate (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.graphTable.parameters = {}
        this.graphTable.measurements = {}
        this.totalNotify = 0
        this.successRate = 0
        this.graphData = {}
        this.graphUnit = ''
        const promise = []
        if (this.previousE) {
          promise.push(this.processSensorValueSignal(this.previousE))
        }
        promise.push(this.fetchSensorAtSelectedDate())
        await Promise.all(promise)
        this.doMergeMarker()
        this.placeMarkers()
      }
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .map {
    height: 100%;
  }

  .cli_component {
    width: calc(100% - 24px);
  }

  .leftPanel {
    width: 100%;
    z-index: 99;
    /*overflow-y: auto;*/
    /* disable overflow here because we need to scroll at content */
    position: absolute;
    /*height: 100%;*/
  }

  .mobileCompanyContentScroller {
    overflow-y: auto;
    height: calc(100% - 88px);
    /* 88px here is company_component.vue */
  }

  .rightPanel {
    width: 100%;
  }

  .card {
    width: 100%;
  }

  .listItemContainer {
    border-top: 2px #DCDCDC solid;
  }
}

@media (min-width: 601px) {
  .map {
    height: 60%;
  }

  .cli_component {
    width: 100%;
  }

  .carouselContainer {
    height: 40%;
  }

  .leftPanel {
    width: 50%;
    max-height: 100%;
    overflow-y: auto;
  }

  .rightPanel {
    width: 50%;
  }

  .card {
    width: 235px;
    height: 90px;
  }
}

.companyPage {
  height: 100%;
  max-height: 100%;
  position: relative;
}

.label {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}

::v-deep .ldmap_placeholder .ldmap_frame .ldmap_popup_detail {
  max-width: unset;
  min-width: 300px;
  max-height: unset;
  padding: unset;
}

::v-deep .ldmap_placeholder .ldmap_frame .ldmap_popup {
  max-width: unset;
}

::v-deep .ldmap_placeholder {
  font: unset;
  font-size: 14px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
}
</style>
