<template>
  <v-carousel :show-arrows="items.length > 1" height="100%" hide-delimiters>
    <v-carousel-item v-for="(item, index) in items" :key="index">
      <template slot="default">
        <div class="d-flex carouselItemContainer">
          <v-img :src="item.src" class="align-self-center" contain height="100%" width="100%">
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="imagePlaceholder py-2 px-3 d-flex">
            <div style="max-width: calc(100% - 24px); overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap;">
              <span>{{ item.description || item.src }}</span>
              <br v-if="item.author"/>
              <span v-if="item.author">โดย : {{ item.author }}</span>
            </div>
            <v-spacer/>
            <div class="d-flex align-center">
              <v-icon>mdi-information-outline</v-icon>
            </div>
          </div>
        </div>
      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'myCarousel',
  props: ['src', 'author', 'description'],
  computed: {
    items () {
      const maxLength = Math.max(this.src.length, this.author.length, this.description.length)
      const data = []
      for (let i = 0; i < maxLength; i++) {
        const item = {
          src: this.src[i] || '',
          description: this.description[i] || '',
          author: this.author[i] || ''
        }
        data.push(item)
      }
      return data
    }
  }
}
</script>

<style scoped>
.carouselItemContainer {
  position: relative;
  height: 100%
}

.imagePlaceholder {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
}
</style>
