<template>
  <div style="width: 100%; position: relative;">
    <div v-show="isShowGraph" id="CompanyLineChart" style="width: 100%;"></div>
    <div v-if="isShowGraph" class="d-flex justify-center align-center"
         style="position: absolute; bottom: 20px; width: 100%; font-size: 11px;">
      <div v-for="item in graphData.threshold" :key="item.severity" class="d-flex">
        <div :style="'background-color: ' + severityColor[item.severity]" class="mr-2"
             style="height: 12px; width: 12px;"></div>
        <span class="mr-2">
          <span v-if="item.leftValue" class="mr-1">{{ numberWithCommas(item.leftValue || '') }}</span>
          <span v-if="item.leftValue && item.leftCondition" class="mr-1">{{ item.leftCondition }}</span>
          <span class="mr-1">ค่ามลพิษ</span>
          <span v-if="item.rightValue && item.rightCondition" class="mr-1">{{ item.rightCondition }}</span>
          <span v-if="item.rightValue">{{ numberWithCommas(item.rightValue || '') }}</span>
        </span>
      </div>
    </div>
    <div v-if="!isShowGraph" class="d-flex justify-center align-center rounded-xl"
         style="height: 150px;border: 2px solid #DCDCDC;">
      <v-progress-circular v-if="isLoading" color="mb-3" indeterminate></v-progress-circular>
      <div v-else class="text-center">
        <span v-if="isNoData">ไม่มีข้อมูล</span>
        <br v-if="isNoData"/>
        <span>กรุณาเลือกข้อมูลด้านบนเพื่อแสดงผล</span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'
import Highcharts from 'highcharts'
import Exporting from 'highcharts/modules/exporting'

Exporting(Highcharts)

export default {
  name: 'CompanyLineChart',
  props: ['graphData', 'isLoading', 'graphUnit'],
  data () {
    return {
      rawData: [],
      category: [],
      processedData: [],
      zones: [],
      severityColor: {
        1: '#73bf12',
        2: '#e0ac00',
        3: '#bf1c12'
      }
    }
  },
  methods: {
    numberWithCommas (x) {
      return utils.numberWithCommas(x)
    },
    processThreshold () {
      this.zones = this.graphData.threshold.map(
        item =>
          ({
            value: item.rightValue,
            color: this.severityColor[item.severity]
          })
      )
      this.zones.push({ value: 9999999, color: 'black' }) // if value is more than red zone the color will goes black.
    },
    processCategory () { // return x-axis labels
      this.category = this.graphData.values.map(
        (item, index) => {
          return item.time
        }
      )
    },
    GenerateChart () {
      Highcharts.chart('CompanyLineChart', {
        chart: {
          height: 250
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.category
        },
        yAxis: {
          title: {
            text: 'ระดับค่ามลพิษ' + ' (' + this.graphUnit + ')'
          }
        },
        series: [
          {
            name: 'ระดับค่ามลพิษ',
            data: this.graphData.values.map(item => item.value),
            zones: this.zones
          }
        ],
        tooltip: { // styling hover div
          formatter: function () {
            return `<span style="font-size: 10px;">${this.x}</span><br/><span>${this.series.name}: <b>${utils.numberWithCommas(this.y || '')}</b></span>`
          }
        }
      })
      window.dispatchEvent(new Event('resize')) // if not resize highcharts will create a chart which larger than div
    }
  },
  watch: {
    graphData (newVal, oldVal) {
      if (Object.keys(newVal).length > 0) {
        this.processCategory()
        this.processThreshold()
        this.GenerateChart()
      }
    }
  },
  computed: {
    isShowGraph () {
      const hasData = Object.keys(this.graphData).length > 0
      return !this.isLoading && hasData && !this.isNoData
    },
    isNoData () {
      return this.graphData.values && this.graphData.values.length <= 0
    }
  }
}
</script>

<style scoped>
::v-deep .highcharts-legend-item {
  display: none;
}

::v-deep .highcharts-exporting-group {
  display: none;
}

::v-deep text {
  font-family: "Roboto", 'Prompt', sans-serif !important;
}
</style>
