<template>
  <v-dialog v-model="isOpenDialog" max-width="800">
    <v-card>
      <v-card-title>
        <span class="secondary--text">ส่งออกรายงาน</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <div class="py-4 px-6 px-sm-16 black--text">
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              ชื่อ
            </v-col>
            <v-col cols="">
              <v-text-field :value="data.name" color="search" dense hide-details outlined readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              ประเภท
            </v-col>
            <v-col cols="">
              <v-select v-model="selectedMeasurementType" :items="measurementType" color="search" dense hide-details
                        item-color="search-item" item-text="name" item-value="id" outlined
                        placeholder="--เลือก--"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              จุดตรวจวัด
            </v-col>
            <v-col cols="">
              <v-select v-model="data.measurement" :items="measurementForSelect" color="search" dense hide-details
                        item-color="search-item" item-text="name" item-value="id" outlined
                        placeholder="--เลือก--"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              พารามิเตอร์
            </v-col>
            <v-col cols="">
              <v-select v-model="data.parameter" :items="forShowParameter" color="search" dense hide-details
                        item-color="search-item" item-text="name" item-value="id" outlined
                        placeholder="ทั้งหมด"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              ความถี่
            </v-col>
            <v-col cols="">
              <v-select v-model="data.frequency" :items="frequentList" color="search" dense hide-details
                        item-color="search-item" item-text="name" item-value="id" outlined
                        placeholder="--เลือก--"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center " cols="3" sm="2">
              วันที่เริ่ม
            </v-col>
            <v-col cols="9" sm="4">
              <my-date-picker v-model="data.datefrom"></my-date-picker>
            </v-col>
            <v-col class="d-flex align-center " cols="3" sm="2">
              วันที่สิ้นสุด
            </v-col>
            <v-col cols="9" sm="4">
              <my-date-picker v-model="data.dateto" :min="data.datefrom"></my-date-picker>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-end" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn class="ml-3" color="search-item white--text" elevation="0" @click="doExport();">
            ส่งออก CSV
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MyDatePicker from '../myDatePicker'
import { mapState } from 'vuex'
import utils from '../../assets/js/utils'

export default {
  name: 'ExportCompany',
  components: { MyDatePicker },
  props: {
    isShow: Boolean,
    value: Object,
    measurementList: Array
  },
  mounted () {
    this.$store.dispatch('ref/getMeasurementType')
  },
  data () {
    return {
      data: {
        id: 0,
        measurement: '',
        frequency: '',
        datefrom: '',
        dateto: ''
      },
      isOpenDialog: false,
      selectedMeasurementType: 0,
      frequentList: [
        {
          id: 'hourly',
          name: 'รายชั่วโมง'
        },
        {
          id: 'daily',
          name: 'รายวัน'
        },
        {
          id: 'monthly',
          name: 'รายเดือน'
        },
        {
          id: 'annual',
          name: 'รายปี'
        }
      ],
      filteredParameter: []
    }
  },
  methods: {
    doExport () {
      this.getExportUrl()
    },
    getExportUrl () {
      const payload = { ...this.data, uuid: this.uuid }
      if (payload.name !== undefined) delete payload.name
      const query = new URLSearchParams(payload).toString()
      const url = process.env.VUE_APP_WS_BASE + '/export/measurement/?' + query
      if (utils.getDeviceType() === 3) {
        window.open(url, 'Download')
      } else {
        window.longdoJsInterface.openUrl({ url: url, title: 'ส่งออกข้อมูล' })
          .then(
            (result) => {
              if (!result) console.warn('open url not success with unknown error')
            },
            (error) => {
              if (error.code !== 1) console.warn('open url not success with this code', error)
            }
          )
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      this.filteredParameter = []
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    async 'data.measurement' (to, from) {
      if (to) {
        this.filteredParameter = await this.$store.dispatch('ref/getParameterList', { id_parent: to })
      }
    }
  },
  computed: {
    ...mapState('ref', ['measurementType']),
    ...mapState('user', ['uuid']),
    measurementForSelect () {
      let tmp = this.measurementList
      if (this.selectedMeasurementType) {
        tmp = tmp.filter(item => item.measType.id === this.selectedMeasurementType)
      }
      return tmp.map(item => ({ id: item.id, name: item.code }))
    },
    forShowParameter () {
      return [{ id: 0, name: 'ทั้งหมด' }, ...this.filteredParameter]
    }
  }
}
</script>

<style scoped>

</style>
